<template>
  <v-alert
    v-if="visible"
    :color="color"
    :title="translations.connectionStatus[status]"
  />
  <v-spacer v-else />
</template>
<script lang="ts" setup>
import translations from "@/translations";
import { ref, computed, watch, onMounted } from "vue";
import { wsLink } from "@/plugins/apollo";
import { ConnectionStatus } from "../../utils/translations";

// Data
const status = ref("DISABLED" as ConnectionStatus);
const hasErrored = ref(false);
const visible = ref(true);
const timeout = ref(
  setTimeout(() => {
    // dummy
  }),
);

// Computed
const color = computed(() => {
  if (status.value === "DISCONNECTED") return "error";
  if (status.value === "CONNECTING") return "warning";
  if (status.value === "CONNECTED") return "success";
  if (status.value === "DISABLED") return "";
  throw new Error("Invalid status");
});

// Watch
watch(status, (newStatus) => {
  clearTimeout(timeout.value);
  if (newStatus === "CONNECTED")
    timeout.value = setTimeout(() => {
      visible.value = false;
    }, 1500);
  else visible.value = true;
});

// Mounted
onMounted(() => {
  const client = wsLink.client;
  client.on("connecting", () => {
    status.value = ConnectionStatus.CONNECTING;
  });
  client.on("opened", () => {
    status.value = ConnectionStatus.CONNECTED;
  });
  client.on("closed", (reason) => {
    if (!hasErrored.value) {
      // @ts-expect-error Reason is not typed: https://github.com/enisdenjo/graphql-ws/blob/master/src/client.ts#L167
      if (reason.wasClean) status.value = "DISABLED";
      else status.value = ConnectionStatus.DISCONNECTED;
      hasErrored.value = false;
    }
  });
  client.on("error", () => {
    hasErrored.value = true;
    status.value = ConnectionStatus.DISCONNECTED;
  });
});
</script>
